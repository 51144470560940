@import '../../styles/variables.scss';
@import '../../styles/base.scss';

h1.heading {
    padding-bottom: 4rem;
}
.contact-wrapper {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}
.info-box {
    text-align: left;
}
.map-container {
    width: 60%;
    height: 600px;  
    padding-bottom: 30px;
    margin: auto;
}
.google-map {
    width: 100%;
    height: 100%;
    border: none;
    
}
@media (max-width: 768px) {
    .contact-wrapper {
        flex-direction: column;
        justify-content: space-around;
        
    }
    .map-container {
        width: 80%;
        height: 400px;  
        margin: auto;
    }
    .info-box {
        text-align: center;
        padding-bottom: 10px;
    }
    
}

