.section {
  display: block;
  background-color: #fff;
  padding: 2rem 1rem;
  &.secondary {
    background-color: $brand-secondary;
  }
}
.block-container {
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 460px) {
  .block-container {
    max-width: 100%;
  }
}
