.App {
  text-align: center;
}


@media (min-width: 797px) {
  .page-container {
    min-height: calc(100vh - 258px);
  } 
}


