@import '../../styles/variables.scss';
@import '../../styles/base.scss';

h1.heading {
    padding-bottom: 4rem;
}
.blurb-wrapper {
    width: 50%;
    text-align: center;
    padding-bottom: 4rem;
    margin: auto;
}


@media screen and (max-width: 1130px) {
    .blurb-wrapper {
        width: 100%;
    }
}
