@import '../../styles/variables.scss';

.main-nav {
    //width: 100%;
    background-color: $brand-primary;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 2px 10px;
    box-sizing: border-box;
    //overflow: hidden;
    font-size: 1.2rem;
}
.sticky {
    position: fixed;
    top: 0;
    z-index: 1;
}



.brand-wrapper {
    padding: 0;
    margin: auto 20px;
    display: flex;
    justify-content: space-between;
}



.brand-title {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    justify-content: space-around;
    line-height: 1rem;
    color: $brand-logo;
    font-weight: 600;
    &__top {
        font-size: 25px;
    }
    &__bottom {
        font-size: 18px;
    }
} 



.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: fit-content;
    justify-content: end;
    margin-right: 2rem;
    margin: auto 0;
}
  
.nav-item {
    display: flex;
    align-items: center;
    height: 80px;
}
  
.nav-link {
    color: $font-color-primary;
    font-size: 1.3rem;
    text-decoration: none;
    padding: 0.5rem 1rem;
    &:hover {
    color: black;
    border-radius: 4px;
    transition: all 0.2s ease-out;
  }
}

.icon {
    width: 25px;
    height: 2px;
    background-color: $font-color-primary;
    margin: 6px 0;
}


.fa-bars {
    color: rgb(0, 0, 0);
}
  
.nav-link-mobile {
    display: none;
}
  
.menu-icon {
    display: none;
}

//########### mobile ##############

@media screen and (max-width: 1130px) {
    
  .fa-caret-down {
    display: none;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    text-align: center;
    align-items: center;
  }

  .nav-menu.active {
    background: $brand-side-menu;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    padding: 0;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;

  }

  .fa-times,
  .fa-bars {
    color: $brand-logo;
    font-size: 2rem;
  }
  

  // .nav-links-mobile {
  //   display: block;
  //   text-align: center;
  //   padding: 1.5rem;
  //   margin: 2rem auto;
  //   border-radius: 4px;
  //   width: 80%;
  //   background: #1888ff;
  //   text-decoration: none;
  //   color: #fff;
  //   font-size: 1.5rem;
  // }

  // .nav-links-mobile:hover {
  //   background: #fff;
  //   color: #1888ff;
  //   transition: 250ms;
  // }

  button {
    display: none;
  }
}
